<template>
  <div>
    <b-card>
      <b-card-text>
        <div class="row">
          <div class="col-md-6 mb-5">
            <div class="row">
              <div class="col-md-12">
                <b-form-group label="Documento cliente:">
                  <b-form-input
                    v-model="form.dni"
                    type="text"
                    required
                    readonly
                  ></b-form-input>
                </b-form-group>
                <b-form-group label="Nombre cliente:">
                  <b-form-input
                    v-model="form.full_name"
                    type="text"
                    required
                    readonly
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-md-12">
                <b-form-group label="Hora de atención:" label-for="input-1">
                  <b-form-input
                    v-model="form.hour_of_service"
                    type="datetime-local"
                  ></b-form-input>
                </b-form-group>
              </div>
            </div>
            <h4>Servicios seleccionados</h4>
            <b-table :items="selectedProducts" :fields="selectedProductFields">
              <template #cell(price)="data">
                {{ getFixedPrice(data.item.price) }}
              </template>
              <template #cell(total)="data">
                {{ getFixedPrice(data.item.total) }}
              </template>
              <template #cell(actions)="data">
                <b-button
                  variant="danger"
                  @click="removeSelectedProduct(data.item)"
                  >X
                </b-button>
              </template>
            </b-table>
            <div class="row">
              <div class="col-md-6" v-if="buttonSellIsVisible">
                <h3>S/ {{ getFixedPrice(total) }}</h3>
              </div>
              <div class="col-md-6">
                <b-button
                  variant="primary"
                  v-if="buttonSellIsVisible"
                  @click="saveSell"
                  style="float: right"
                  :disabled="disabledSellbutton"
                  >Venta
                </b-button>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="col-md-12">
              <multiselect
                v-model="specialty"
                track-by="id"
                label="name"
                placeholder="Selecciona un item para filtrar"
                :options="specialties"
                :searchable="true"
                :allow-empty="true"
                @input="buscarConLaboratorio"
              >
              </multiselect>
              <label class="typo__label">Buscar</label>
              <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                placeholder="Ingrese el nombre"
              ></b-form-input>
              <b-table
                :items="products"
                :fields="productFields"
                :filter="filter"
                :current-page="currentPage"
                :per-page="perPage"
                @filtered="onFiltered"
              >
                <template #cell(quantity_to_sell)="data">
                  <b-input
                    type="number"
                    step="1"
                    value="1"
                    :id="'quantity-' + data.item.id"
                  ></b-input>
                </template>
                <template #cell(action)="data">
                  <b-button
                    size="sm"
                    class="mb-2"
                    variant="success"
                    :id="'check-' + data.item.id"
                    @click="selectProduct(data.item)"
                  >
                    <b-icon icon="check" aria-hidden="true"></b-icon>
                  </b-button>
                </template>
              </b-table>
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="fill"
                size="sm"
                class="my-0"
              ></b-pagination>
            </div>
          </div>
        </div>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import Multiselect from "vue-multiselect";

export default {
  name: "ProceduresCreate",
  metaInfo: {
    title: "Seleccionar servicios | MAYTOK MEDICAL"
  },
  components: {
    Multiselect
  },
  data() {
    return {
      disabledSellbutton: false,
      customerName: null,
      specialties: [],
      form: {
        dni: null,
        person: null,
        full_name: "",
        hour_of_service: "2021-01-01T19:30",
        customer: null
      },
      persons: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 7,
      filter: null,
      product: null,
      products: [],
      selectedProducts: [],
      specialty: null,
      selectedProductFields: [
        {
          key: "name",
          label: "Nombre"
        },
        {
          key: "quantity",
          label: "Cant."
        },
        {
          key: "price",
          label: "Precio"
        },
        {
          key: "total",
          label: "Total"
        },
        {
          key: "actions",
          label: ""
        }
      ],
      productFields: [
        {
          key: "name",
          label: "Nombre"
        },
        {
          key: "price",
          label: "Precio"
        },
        {
          key: "doctor_name",
          label: "Especialista"
        },
        {
          key: "action",
          label: "Acciones"
        }
      ]
    };
  },
  methods: {
    getSpecialties() {
      ApiService.get("specialty").then(res => {
        this.specialties = res.data;
      });
    },
    buscarConLaboratorio(value) {
      let query = "";
      if (value !== null) {
        query = `?specialty=${value.id}`;
      }
      console.log(value);
      ApiService.get("service/catalog", query).then(res => {
        this.products = res.data.services;
        this.totalRows = this.products.length;
      });
    },
    makeToast(variant = null) {
      this.$bvToast.toast("Éxito al guardar", {
        title: `Todo bien`,
        variant: variant,
        solid: true
      });
    },
    makeToastError() {
      this.$bvToast.toast("Ha ocurrido un error", {
        title: "Oooops",
        variant: "danger",
        solid: true
      });
    },
    removeSelectedProduct(item) {
      this.selectedProducts = this.customLodash.filter(
        this.selectedProducts,
        function(el) {
          return el.id !== item.id;
        }
      );
    },
    saveSell() {
      this.disabledSellbutton = true;
      ApiService.post("service/sell/", {
        products: this.selectedProducts,
        person: this.$route.query.person,
        hour_of_service: this.form.hour_of_service
      })
        .then(res => {
          this.makeToast("success");
          console.log(res);
          this.axios.post("http://localhost:3030/orthoray/", res.data);
          this.axios.post("http://localhost:3030/orthoray/", res.data);
          setTimeout(() => {
            this.$router.push({ name: "clinic_appointment_list" });
          }, 1500);
        })
        .catch(() => {
          this.makeToastError();
        });
    },
    getFixedPrice(price) {
      return price.toFixed(2);
    },
    selectProduct(product) {
      let quantity = 1;
      // Check if product exists
      let index = this.customLodash.findIndex(this.selectedProducts, {
        id: product.id
      });
      if (index === -1) {
        let prod = {
          id: product.id,
          quantity: parseInt(quantity),
          price: parseFloat(product.price),
          original: parseFloat(product.price),
          total: parseFloat(quantity) * parseFloat(product.price),
          name: product.name,
          doctor: product.doctor_id,
          specialty: product.specialty_id,
          specialty_name: product.specialty_name,
          doctor_name: product.doctor_name
        };
        this.selectedProducts.push(prod);
      } else {
        let currentQuantity = this.selectedProducts[index]["quantity"];
        currentQuantity += parseInt(quantity);
        this.selectedProducts[index]["quantity"] = currentQuantity;
        this.selectedProducts[index]["total"] =
          currentQuantity * parseFloat(product.price);
      }
    },
    onFiltered(filteredItems) {
      console.log(filteredItems);
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getProducts() {
      ApiService.get("service/catalog").then(res => {
        this.products = res.data.services;
        this.totalRows = this.products.length;
      });
    },
    setPerson() {
      let person = this.$route.query.person;
      ApiService.get(`person/${person}`).then(res => {
        console.log("set person", res.data);
        this.form.full_name = res.data.full_name;
        this.form.dni = res.data.dni;
        this.form.person = res.data.id;
      });
    },
    getCurrentDateTimeFormat() {
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      let yyyy = today.getFullYear();
      let hour = String(today.getHours()).padStart(2, "0");
      let minutes = String(today.getMinutes()).padStart(2, "0");
      let formatted = yyyy + "-" + mm + "-" + dd + "T" + hour + ":" + minutes;
      console.log(formatted);
      this.form.hour_of_service = formatted;
    }
  },
  computed: {
    total() {
      return this.customLodash.sumBy(this.selectedProducts, "total");
    },
    buttonSellIsVisible() {
      return this.selectedProducts.length > 0;
    }
  },
  mounted() {
    this.getSpecialties();
    this.setPerson();
    this.getProducts();
    this.getCurrentDateTimeFormat();
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Agendar" },
      { title: "Servicios" }
    ]);
  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
